:root {
	--dark-red: #910e0e;
	--dark-blue: #6d99c6;
  --selection: rgb(60, 179, 113);
  --hover-selection: rgba(60, 179, 113, 0.85);
}
html {
  font-family: Quicksand, avenir, sans-serif;
}
html, body {
	width: 100vw;
	height: 100vh;
	overflow: auto;
	padding: 0;
	margin: 0;
}

#actions {
	position: absolute;
	bottom: 5px;
	right: 20px;
}

#actions button {
  cursor: pointer;
  background-color: var(--hover-selection);
  color: white;

  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
}

#actions button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.github-link {
  display: inline-block;
  position: absolute;
  z-index: 100;
  bottom: 5px;
  left: 5px;
  font-size: 110%;
  font-style: italic;
  background-color: white;
  border-radius: 20px;
  padding: 5px 10px;
  text-decoration: none;

  color: royalblue;
}