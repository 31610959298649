.dialog {
  width: 300px;
}
.dialog p {
	margin: 0;
	font-size: 90%;
	font-weight: bold;
}
.dialog input {
	display: block;
	width: 100%;
	height: 30px;
	line-height: 30px;

	font-size: 120%;
	margin: 0;
	padding: 4px;
	border: none;
	border-radius: 4px;
  box-sizing: border-box;
}
ul.results {
	font-family: monospace;
	list-style-position: inside;
	list-style-type: none;
	max-height: 200px;
	overflow: scroll;
	padding: 4px;
  margin: 4px 0;
	background: rgba(0, 0, 0, 0.8);
	border-radius: 4px;
}
.results li {
	cursor: pointer;
	color: white;
	padding: 5px;
}
.results li:hover, .results li.highlighted {
	background: white;
	color: black;
}
.results li b { color: red; }

.choices-counter {
  font-size: 10px;
}

.choices-counter a {
  color: var(--selection);
  border-bottom: 1px dotted var(--selection);
  cursor: pointer;
}
